import React, { Component } from "react";

import Accent from "../Accent";

import LinkButton from "../LinkButton";

import styles from "./styles.module.less";
import BranchMap from "../BranchMap";

class FindLO extends Component {
    render() {
        return (
            <section data-background="white" className={styles.FindLO}>
                <div className={styles.inner}>
                    <div className={styles.container}>
                        <div className={styles.text}>
                            <h1 className={styles.title}>
                                Find your <br />
                                loan officer
                            </h1>
                            <Accent align="left" />
                            <p>
                                With hundreds of local teams across the country,
                                chances are pretty good we’re in your
                                neighborhood. <br />
                                Look us up.
                            </p>
                            <div className={styles.linkBtn}>
                                <LinkButton
                                    label="Find Your Team"
                                    href="/find-your-loan-officer"
                                />
                            </div>
                        </div>
                        <BranchMap />
                    </div>
                </div>
            </section>
        );
    }
}

export default FindLO;
